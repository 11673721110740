<template>
  <Container>
    <b-row align-h="center">
      <b-col md="4">
        <Card padding="medium">
          <Margins>
            <h1 class="h3 text-center">{{ $t('FORGOT_PASSWORD') }}</h1>

            <p class="text-center text-small">
              {{ $t('ABOUT_LOST_PASSWORD') }}
            </p>

            <form @submit="handleSubmit" class="margins__double">
              <Margins>
                <InputField
                  :label="`${$t('FORM.EMAIL')}`"
                  name="email"
                  v-model.trim="formData.email"
                  :error="errors.first('email')"
                  v-validate="{ required: true }"
                  :data-vv-as="$t('FORM.VALIDATOR.EMAIL')"
                  autocomplete="username"
                />

                <div class="text-center margins__double">
                  <Button
                    type="submit"
                    :loading="isLoading"
                    :disabled="isLoading"
                    >{{ $t('SEND_EMAIL') }}</Button
                  >
                </div>

                <p
                  class="text-center text-small text-grey margins__double"
                  v-html="contactMessage"
                />
              </Margins>
            </form>
          </Margins>
        </Card>
      </b-col>
    </b-row>
  </Container>
</template>

<script>
import { Card, Container, InputField, Margins, Button } from '@/components';
import { mapActions, mapState } from 'vuex';
import { BACK_OFFICE_PASSWORD_LOST } from '@/types';
import { constants } from '@/mixins';
import { SUPPORT_EMAIL } from '@/constants';

export default {
  name: 'BackOfficePasswordLost',
  mixins: [constants],
  data() {
    return {
      formData: {},
    };
  },
  components: {
    Container,
    Card,
    Margins,
    InputField,
    Button,
  },
  computed: {
    ...mapState(['backOfficeLoggedIn', 'isLoading']),
    contactMessage() {
      return this.$t('CONTACT_LOST_PASSWORD', [
        `<a href="mailto:${SUPPORT_EMAIL}">${SUPPORT_EMAIL}</a>`,
      ]);
    },
  },
  methods: {
    ...mapActions([BACK_OFFICE_PASSWORD_LOST]),
    async handleSubmit(e) {
      e.preventDefault();
      const valid = await this.$validator.validateAll();
      if (!valid) {
        return false;
      }
      const notification = this.$t('PASSWORD_CHANGE_EMAIL_SUCCESS');
      await this.BACK_OFFICE_PASSWORD_LOST({
        formData: this.formData,
        notification,
      });
      this.$validator.reset();
    },
  },
};
</script>
